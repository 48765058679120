.NewsWraper .News_Container
{
    display: flex;
    justify-content: center;
    align-items: center;
}

.NewsWraper
{
    margin: 15px 20px 0px 0px;
    display: inline-block;
}
/* .News_Image
{
    margin-left: 50px;
} */
.News_Image img {
    border-radius: 12px;
}


.News_Container   p
{
    cursor: pointer !important;
}
.NewsSlider {
    padding: 12px 5% 15px 5%;
}
/* .News_head
{
    width: 75%;
} */


@media only screen and (max-width: 1350px)
    {
    .NewsWraper .News_Container {
        flex-direction: column;
        align-items: start;
    }
    .NewsText {
        width: 90% !important;
    }
    .News_Image {
        margin-left: 0 !important;
        margin-top: 25px !important;
    }
    /* .NewsWraper .News_Container {
        align-items: start;
    } */
    .NewsSlider {
        padding: 12px 5% 15px 10% !important;
    }
}

@media only screen and (max-width: 1200px)
    {
        .NewsSlider {
            padding: 12px 10% 15px 10% !important;
        }
        .NewsText
        {
            height: 150px;
        }
    }

    @media only screen and (max-width: 800px)
    {
        .NewsSlider {
            padding: 12px 10% 15px 10% !important;
        }
        .NewsText
        {
            height: 132px;
        }
    }
    @media only screen and (max-width: 576px)
    {
        .NewsSlider {
            padding: 12px 20% 15px 20% !important;
        }
        .NewsText
        {
            height: auto;
        }
        .NewsWraper .News_Container
        {
            align-items: start !important;
        }
        .NewsText {
            width: 95% !important;
        }
        .NewsText p:nth-child(2)
        {
            font-size: 2.5rem !important; 
        }
        .NewsText p:nth-child(3)
        {
            font-size: 1.9rem !important; 
        }
        .News_Date p 
        {
            font-size: 1.4rem;
        }
        .NewsWraper {
            margin: 15px 0px 0px 0px;
        }
    }

    /* .News_Container .News_Image img
    {
        width: 276px;
    } */