

.socialmedia svg
{
    width: 3%;
}
.logo
{
    padding: 10px 4%;
    text-align: end;
}
.body
{
    text-align: center;
    background: transparent;
    position: absolute;
    width: 100%;
    bottom: 40px;
}
main
{
    position: relative;
    height: 100%;
}
.body .notifyBtn a
{
    text-decoration: none;
    display: inline-block;
    background: black;
    color: #E8E8E8    ;
    padding: 1% 3.5%;
    border-radius: 38px;
    transition: width 0.2s cubic-bezier(0.175, 0.885, 0.320, 1.275);
    width: 234px;
    white-space: nowrap;
}
.body .notifyBtn a:hover
{
    width: 264px;
}
.bodytext
{
    margin: -45px 0px 30px 0px;
}
.bodytext h2
{
    margin-bottom: 0px !important;
    color: black;
    font-size: 3rem;
}
.bodytext p
{
    margin-top: 10px !important;
    color: black;
    font-size: 2rem;
}
.notifyBtn
{
    margin-bottom: 45px;
}
.notifyBtn a
{
    font-size: 2rem;
}
.socialmedia p
{
    color: black;
    font-size: 1.5rem;
}



.sparticles
{
    position: absolute;
    top: 0;
    z-index: -999;
}

.contactus
{
    display: flex;
    overflow: hidden;
    background-color: black;
}
.contactForm
{
    width: 62%;
    background: black;
    padding:20px 0px 0px 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.contactForm h2
{
    color: white;
    font-size: 3rem;
}
.contactForm form input,
.contactForm form textarea
{
    display: block;
    width:78%;
    background-color: transparent;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #cccccc;
    padding: 45px 0px 5px 0px;
    color: white;
    font-size: 1.8rem;
}
.contactForm form
{
    position: relative;
}
.contactForm form input:nth-child(1)
{
    padding: 25px 0px 5px 0px;
}
.contactForm form input::placeholder,
.contactForm form textarea::placeholder
{
    color: #5b5b5b !important;
}
.contactForm form textarea {
    resize: none;
  }
  .contactForm form input:focus-visible,
.contactForm form textarea:focus-visible
{
    outline: none !important;
}
.contactForm form input::placeholder,
.contactForm form textarea::placeholder
{
    color: white;
}
.contactForm form .submit
{
    text-decoration: none;
    display: inline-block;
    background: #4B4B4B;
    color: #E8E8E8    ;
    padding: 2% 5.5%;
    border-radius: 38px;
    width: auto;
    border: none;
    cursor: pointer;
    font-size: 2rem;
    width: 234px;
    white-space: nowrap;
    transition: width 0.2s cubic-bezier(0.175, 0.885, 0.320, 1.275);
}
.contactForm form .submit:hover
{
    width: 264px;
}

.bottomText
{
    margin-top: 40px;
}
/* .bottomText p:nth-child(2),
.bottomText p:nth-child(3),
.bottomText p:nth-child(3)
{
    color: white;
    font-size: 1.5rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin: 0px 0px 15px 0px;
}  */
.bottomText p:nth-child(2) span,
.bottomText p:nth-child(3) span
{
    margin: 0px 5px;
}

.sideImage
{
    width: 30%;
    position: relative;
}
.sideImage img
{
    width: 100%;
    height: 100%;
}
.sideImage a
{
    position: absolute;
    width: 50px;
    height: 50px;
    background: white;
    right: 38px;
    z-index: 999;
    top: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
}
.sideImage a svg
{
    width: 50%;
}

.thankyou_message {
    display: none;
    border-radius: 40px;
    padding: 5px 15px 5px;
    color: white;
    font-family: 'Titillium Web', sans-serif;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    bottom: 0px;
    background: white;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    width: 50%;
}
.thankyou_message a
{
    background: white;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    margin-left: 15px;
}
.thankyou_message a svg
{
    width: 45%;
}
.thankyou_message h1
{
    color: black;
}

.socialmedia
{
    text-align: left;
    padding-left: 4%;
}

.press-effect {
    padding: 5px;
    font-size: 14px;
    cursor: pointer;
    min-width: 120px;
    margin: 15px 5px;
    min-height: 20px;
    text-decoration: none;
    border-bottom: 4px solid #d9d9d9;
    box-shadow: 0px 5px 10px #333;
    -webkit-transition: all 0.3s;
    transition: all 0.4s;

    color: white;
    background: #273889;
    font-size: 2rem;
    font-weight: 300;
    font-family: Titillium Web,sans-serif;
    border: none;
    padding: 10px 30px 10px 30px;
    border-radius: 0;
    width: auto;
    text-transform: auto;
    white-space: nowrap;
  }
  
  .press-effect:active {
    box-shadow: 0px 4px 8px #333;
    -webkit-transform: scale(0.78);
    transform: scale(0.78);
  }

  .bottomText svg
  {
    height: 16px;
    fill: white;
  }
  .locationlogo
  {
    display: flex;
    color: white;
  }
  .locationlogo span
  {
    margin: 0px 5px;
    font-size: 1.5rem;
  }
  .ContactDetails
  {
    border-top: 1px solid #636363;
    display: flex;
    align-items: center;
    padding-top: 20px;
    margin-top: 50px;
    padding-bottom: 50px;
  }
  .PhoneDetails
  {
    display: flex;
    align-items: center;
  }
  .EmailDetails
  {
    display: flex;
    align-items: center;
    margin-left: 50px;
  }
  .EmailDetails a
  {
    color: white;
    font-size: 1.6rem;
    text-transform: none;
    color: white;
    font-weight: 400;
    margin: 0px 0px 0px 10px;
  }


    /* office address and locations section */
    .officelocationHead,.UaeofficelocationHead
    {
        border-bottom: 1px solid #4B4B4B;
    }
    .officelocationHead p,.UaeofficelocationHead p
    {
        border-left: 5px solid white;
        padding-left: 10px;
        margin-bottom: 10px !important;
    }
    .officelocationAddress
    {
        display: flex;
        justify-content: space-between;
    }
    .OfficeAddressSec P:nth-child(1)
    {
        border-bottom: 1px solid #4B4B4B;
        padding-bottom: 10px;
    }
    .officelocationHead .OfficeAddressSec
    {
        width: 50%;
    }
    .OfficeAddressSec p:nth-child(2)
    {
        padding-right: 40%;
    }


    @media screen and (max-width: 1200px)
    {
        .contactForm {
            width: 60%;
            background: black;
            padding: 20px 50px 0px 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .OfficeAddressSec p:nth-child(2) {
            padding-right: 25%;
        }

    }

@media screen and (max-width: 480px) {
    .body {
        margin-top: 0px;
    }
    .fighterImg
    {
        width: 60%;
    }
    .body .notifyBtn a,
    .contactForm form .submit {
        padding: 3% 3.5%;
        width: 150px;
    }
    .body .notifyBtn a:hover,
    .contactForm form .submit:hover {
        width: 180px;
    }
    .socialmedia svg {
        width: 10%;
    }
    .sideImage
    {
        display: none;
    }
    .contactForm {
        width: 100%;
        background: black;
        padding: 6% 0% 3% 6%;
    }
    .logo img
    {
        width: 28%;
    }
    .footerItems .col1 a .ClubStoreButton button,
    .footerItems .ClubStoreButton a button
    {
        font-size: 1.5rem !important;
    }
    .bottomText svg {
        height: 14px;
        fill: white;
    }
    .contactForm form .submit {
        font-size: 2.5rem;
    }
    
}


@media screen and (max-width: 380px)
{
    .EmailDetails
    {
      display: flex;
      align-items: center;
      margin-left: 15px;
    }
}