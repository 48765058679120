.PageDescription
{
    padding: 3% 10% 3% 5%;
}
.PageDescription
{
    display: flex;
    justify-content: space-between;
}
.PageDescription .col1
{
    width: 35%;
}
.PageDescription .col2
{
    width: 65%;
}
.PageDescription .col2 p
{
    color: black !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) 
{
        .PageDescription
    {
        padding: 3% 5% 3% 5%;
    }
    .PageDescription .col1
    {
        width: 50%;
    }
    .PageDescription .col2
    {
        width: auto !important;
        margin-top: 25px;
    }
    .PageDescription {
        display: block;
    }
    
}