.leadershipdetails
{
    padding: 3% 5%;
}
.leadershipdetails2
{
    padding: 0% 5%;
    margin-bottom: 80px;
}
.leadershipdetails2
{
    display: flex;
}
.leadershipdetails2 .leadershipImage
{
    width: 35%;
    text-align: center;
}
.leadershipdetails2 .leaderhsipconent
{
    width: 65%;
}
.leadershipImageMob
{
    display:none;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) 
{
    .leadershipImageMob
    {
        display:block;
        text-align: center;
    }
    .leadershipdetails2 {
        display: block;
    }
    .leadershipImage
    {
        display: none;
    }
    .leadershipdetails2 .leaderhsipconent {
        width: 100%;
        margin-top: 25px;
    }
}