.SolutionSliderEachItem  svg
{
    height: 20px;
    fill: #E5E5E5;
}
.SolutionSliderEachItem p
{
    cursor: pointer !important;
}
.SolutionSliderEachItem a:hover svg
{
    fill: black;
}