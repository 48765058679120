.MousCeremonyContent p span,
.PressReleaseTextAfterImg p:nth-child(2) span
{
 font-weight: 800;
}
.PressReleaseImage,
.MousCeremonyPressImage
{
    text-align: center;
}
.PressReleaseImage img
{
    border-radius: 25px;
}

.MousCeremonyPressImageCaption p,
.PressReleaseImageCaption p
{
    width: 60%;
    text-align: center;
    margin: 15px auto;
}
.MousCeremonyPressImageCaption.inotechCap p
{
    width: 50%;
    text-align: center;
    margin: 15px auto;
}
/**/
.TextWithLink p
{
    display: initial;
}
.TextWithLink .ClubStoreButton
{
    display: inline-block;
    margin-left: 3px;
    
}
.TextWithLink .ClubStoreButton a
{
    border-bottom: 1px solid black;
}
.TextWithLink2
{
    display: inline-block;
}
.Azercosmos.TextWithLink2
{
    display: flex;
}
.Azercosmos.TextWithLink2 .ClubStoreButton a
{
    border-bottom: 1px solid black;
}
@media screen and (max-width: 1350px) 
{
    .PressReleaseImage img,
    .MousCeremonyPressImage img
    {
        width: 100% !important;
    }
}


@media screen and (max-width: 850px) 
{
    .MousCeremonyPressImageCaption p,
    .PressReleaseImageCaption p
{
    width: 80%;
    text-align: center;
    margin: 15px auto;
}
}

@media screen and (max-width: 576px) 
{
    .MousCeremonyPressImageCaption p,
    .MousCeremonyPressImageCaption.inotechCap p
{
    width: 90%;
    text-align: center;
    margin: 15px auto;
}

}

