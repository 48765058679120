.DotsSec
{
    display: flex;
    margin-top: 50px;
}
.DotsSec div
{
    display: inline-block;
    height: 15px;
    width: 15px;
}
.DotsSec div:nth-child(1)
{
    background-color: #D9D9D9;
}
.DotsSec div:nth-child(2)
{
    background-color: #505050;
    margin: 0px 10px;
}
.DotsSec div:nth-child(3)
{
    background-color: #000000;
}

.banner
{
    padding: 0% 5%;
    position: relative;
}
.bannerText
{
    position: absolute;
    top: 35%;
    left: 8%;
}
.aboutuscontent
{
    padding: 3% 8% 3% 5%;
}
.whoweareDetails2
{
    display: flex;
    justify-content: space-between;
    margin: 55px 0px 75px 0px;
}


.whoweareDetails2 .col1 {
    width: 35%;
}
.whoweareDetails2 .col2 {
    width: 50%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) 
{
.DotsSec {
    display: flex;
    margin-top: 15px;
    margin-bottom: 15px;
}
.bannerText {
    top: 20%;
}
.bannerText p
{
    display: none;
}
}