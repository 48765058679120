.BreadCrumb .ClubStoreButton+.ClubStoreButton:before {
    padding: 0px 4px 0px 8px;
  color: #6C6C6C;
  content: "/\00a0";
  font-size: 2rem;
  font-weight: bolder;
  font-family: 'Space Grotesk', sans-serif !important;
  }
  .BreadCrumb{
    display: flex;
    margin: 50px 0px 35px 0px;
    padding: 0% 5%;
  }
  .BreadCrumb .ClubStoreButton
  {
    display: flex;
    align-items: center;
  }

  .BreadCrumb .ClubStoreButton p span
  {
    margin: 0px 5px 0px 0px;
  }