.IotAlefSec {
    display: flex;
    justify-content: space-between;
    padding: 3% 7%
}

.AlefService {
    width: 70%;
}

.IotServiceManagement {
    margin-top: 6rem;
    display: flex;
    padding: 3% 7%;
    justify-content: space-between;
}

.IotServiceManagement .col1,
.IotServiceManagement .col2,
.IotServiceManagement .col3,
.IotServiceManagement .col4 {
    width: 20%;
}

.CatalogueButton a {
    color: white;
    background: #3D3D3D;
    font-size: 2rem;
    font-weight: 500;
    font-family: Titillium Web, sans-serif;
    padding: 15px 25px;
    border-radius: 8px;
    cursor: pointer;
}

.CatalogueButton {
    text-align: center;
    margin: 100px 0px 50px 0px;
}

.PowerDeviceManagemnet {
    padding: 0% 7%;
}

.PowerDeviceManagemnetCpanel {
    display: flex;
    justify-content: space-between;
}

.CpanelCol1 {
    width: 60%;
}

.CpanelCol2 {
    width: 35%;
}

.CpanelCol2 {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 35px;
    row-gap: 50px;
}

.PreferredDeploymentMethod {
    padding: 0% 7%;
    margin: 15rem 0rem 12rem 0rem;

}

.DeploymentMethod {
    display: flex;
    justify-content: space-between;
}

.Method1 {
    width: 26%;
}
.DeploymentMethod .Method1 img
{
    width: 125px !important;
}
.DeploymentMethod .Method1:nth-child(3) img
{
    width: 190px !important;
}
.pageDivider {
    position: relative;
}

.pageDivider .RequestDemoBtn {
    position: absolute;
    right: 15%;
    top: 45%;
}

.pageDivider .RequestDemoBtn button {
    /* background-image: linear-gradient(to right, #58a0e9 0%,#113455 45%); */
    padding: 1.6rem 8rem;
    border-radius: 8px;
    background-color: white;
}

.IotServices {
    padding: 0% 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 6rem;
}

.IoTProductsHead {
    padding: 0% 5%;
}

.IotServicesEach {
    width: 22%;
    margin-bottom: 12rem;
    padding-bottom: 8rem;
    position: relative;
}

.IotServicesEach ul {
    padding-left: 0px;
}

.IotServicesEach ul li {
    list-style: none;
    background-image: url(../../public/Images/iotservicesArrow.png);
    background-repeat: no-repeat;
    background-position-y: 55%;
    padding-left: 1.6rem;
}

.IotServicesEach .ClubStoreButton {
    position: absolute;
    bottom: 0;
}

.RequestDemoModal {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #4f4f4f96;
}

.RequestDemoModal .contactForm {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(0%, -50%);
    width: 24vw;
    border-radius: 15px;
    padding: 100px 70px;
    animation: slideLeft 1.2s forwards;

}

.OverLey {
    background-color: #4F4F4F;
}

.RequestDemoModal .contactForm form input,
.RequestDemoModal .contactForm form textarea {
    width: 100% !important;
}
.RequestDemoModal .contactForm form .submit
{
    margin-top: 0px;
}
.Send_Spinner
{
    display: flex;
    align-items: center;
    margin-top: 25px;
}
.sweet-loading
{
    margin-left: 15px;
}

.CloseModal {
    position: absolute;
    top: 20px;
    right: 40px;
    background: white;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    width: 50px;
    height: 50px;
    background-color: white;
}
.CloseModal svg
{
    width: 25px;
}

/* FOR THE Contact  Form */
@keyframes slideLeft {
    0% {
        left: 100%;
    }

    60% {
        left: 34%;
    }

    100% {
        left: 36%;
    }
}

.RequestDemoModal .contactForm form .submit {
    background-color: white;
    color: black;
    font-weight: 600;
    width: auto;
    padding: 15px 75px;
}

.RequestDemoModal .contactForm .thankyou_message {

    width: 90% !important;
    bottom: auto;
    margin-top: 20px !important;
}

/* .pageDivider {
    padding: 0% 5%;
    margin: 0rem 0rem 15rem 0rem;
} */

.pageDivider img {
    width: 100%;
}


/* .IotServices .col1 p:nth-child(2), .IotServices .col2 p:nth-child(2), .IotServices .col3 p:nth-child(2), .IotServices .col4 p:nth-child(2) {
    color: black;
} */
/* .IotServices .col1 span,.IotServices .col2 span,.IotServices .col3 span
{
    visibility: visible;
} */


@media only screen and (max-width: 1200px) 
{
    .RequestDemoModal .contactForm {
        width: 32vw;
    }
    /* FOR THE Contact  Form */
    @keyframes slideLeft {
        0% {
            left: 100%;
        }

        60% {
            left: 26%;
        }

        100% {
            left: 28%;
        }
    }
}



@media only screen and (max-width: 992px) {
    .AlefImg {
        width: 25%;
    }

    .AlefImg img {
        width: 100%;
    }

    .CatalogueButton {
        text-align: left;
    }

    .PowerDeviceManagemnetCpanel {
        display: block;
    }

    .CpanelCol1,
    .CpanelCol2 {
        width: 100%;
    }

    .CpanelCol2 {
        grid-template-columns: auto auto auto;
        margin-top: 50px;
    }

    .IotServicesEach {
        width: 28%;
    }

    .DeploymentMethod .Method1 img {
        width: 50%;
    }

    .DeploymentMethod .Method1:nth-child(3) img {
        width: 76%;
    }

    .pageDivider .RequestDemoBtn {
        top: 30%;
    }

    .CatalogueButton button {
        margin-bottom: 100px !important;
    }

    .RequestDemoModal .contactForm {
        position: fixed;
        width: 45vw;
    }



    /* FOR THE Contact  Form */
    @keyframes slideLeft {
        0% {
            left: 100%;
        }

        60% {
            left: 18%;
        }

        100% {
            left: 20%;
        }
    }

}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .IotServicesEach {
        width: 100%;
    }

    .IotServices .col1,
    .IotServices .col2,
    .IotServices .col3 {
        width: 100%;
    }

    .IotServices .col2 {
        margin: 25px 0px;
    }

    .IotServices .col1 p:nth-child(1),
    .IotServices .col2 p:nth-child(1),
    .IotServices .col3 p:nth-child(1) {
        height: auto;
        margin: 0px 0px 25px 0px !important;
    }

    .IotServices .col3 p:nth-child(1),
    .IotServices .col1 p:nth-child(1) span {
        display: none !important;
    }

    .IotServices .col3 img {
        margin-bottom: 25px;
    }

    .mobhide {
        display: none;
    }

    .CpanelCol2 {
        grid-template-columns: auto;
        margin-top: 50px;
    }

    .CpanleDetail1 {
        width: 75%;
    }

    .DeploymentMethod {
        flex-wrap: wrap;
    }

    .Method1 {
        width: 75%;
    }

    .DeploymentMethod .Method1:nth-child(3),
    .DeploymentMethod .Method1:nth-child(2) {
        margin-top: 50px;
    }

    .pageDivider .RequestDemoBtn button {
        padding: 1rem 5rem
    }

    .pageDivider .RequestDemoBtn {
        top: 22%;
    }

    .RequestDemoModal .contactForm {
        width: 88vw;
    }

    .RequestDemoModal .contactForm {
        padding: 100px 10px;
    }
    .RequestDemoModal .ClubStoreButton button {
        padding: 14px 18px
    }
    .IotServicesEach {
        margin-bottom: 6rem;
    }

    /* FOR THE Contact  Form */
    @keyframes slideLeft {
        0% {
            left: 100%;
        }

        60% {
            left: 1%;
        }

        100% {
            left: 3%;
        }
    }
}