@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
select,
input,
label,
span,
button {
  font-family: 'Space Grotesk', sans-serif !important;
}
p{
  margin: 0px;
}

html {
  /* font-size: 10px !important; */
  overflow-x: hidden;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                        supported by Chrome, Edge, Opera and Firefox */
}
a {
  color: inherit;
  text-decoration: none;
}
a:hover {
  color: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.Page_Body_wraper
{
    max-width: 1600px;
    margin: 0 auto;
}

/* Base styles for all devices */

@media screen and (max-width: 350px) {
  html {
    font-size: 35%;
  }
}

/* Styles for mobile */
@media (min-width: 350px) {
  /* Styles for mobile go here */

  html {
    font-size: 48%;
  }
}

/* Styles for small tablets */
@media screen and (min-width: 768px) {
  /* Styles for small tablets go here */

  html {
    font-size: 52%;
  }
}

/* Styles for tablets */
@media screen and (min-width: 1024px) {
  /* Styles for tablets go here */

  html {
    font-size: 55%;
  }
}

/* Styles for laptops and desktops */
@media screen and (min-width: 1400px) {
  /* Styles for laptops and desktops go here */

  html {
    font-size: 62.5%;
  }
}
/* Styles for laptops and desktops */
@media screen and (min-width: 1920px) {
  /* Styles for laptops and desktops go here */

  html {
    font-size: 72.5%;
  }
}

/* Styles for large screens */
@media screen and (min-width: 2560px) {
  /* Styles for large screens go here */

  html {
    font-size: 76.5%;
  }
}

body {
  background-color: white;
  padding:0;
  margin:0;
}
