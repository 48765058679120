

nav .itemList {
    list-style: none;
    padding: 0;
    margin: 0;
    padding: 30px;
    
  }
  
  nav li {
    position: relative;
    text-align: -webkit-center;
  }
  .itemList  li:nth-child(3),
  .itemList  li:nth-child(4)
  {
    border-bottom: 1px solid #AEAEAE;
    padding: 15px 15px;
  }
  .itemList  li:nth-child(4)
  {
    border-bottom: none;
  }
  nav button {
    display: block;
    background-color: transparent;
    color: #333;
    text-decoration: none;
    border: none;
    cursor: pointer;
    width: 100%;
  }
  
  .dropdown {
    display: none;
    position: unset;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 0px;
    z-index: 2;
    list-style: none;
  }
  
  .dropdown.open {
    display: block;
    animation: slide-down 0.5s forwards;
  }
  
  .dropdown li {
    border-bottom: 1px solid #AEAEAE !important;
    padding: 15px 15px !important;  
  }
  
  @keyframes slide-down {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .mobmenubtn
  {
    display: flex;
    justify-content: space-between;
    padding:  15px 15px 15px;
    border-bottom: 1px solid #AEAEAE;
  }
  .itemList .ClubStoreButton
  {
    text-align: left !important;
  }
  .mobJoin
  {
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 30px 0px;
    margin-top: 40%;
    border-top: 1px solid #A3A3A3;
    width: 100%;
  }
  .mobJoin .ClubStoreButton a button img
  {
    width: 25px !important;
  }