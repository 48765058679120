.headerSec
{
    padding: 3% 6%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 68vh;
    position: relative;
}
.headerSec .headerText p:nth-child(1) span
{
    color: #b8b8b8 !important;
}
.headerSec .headerText .ClubStoreButton
{
    cursor: pointer;
    display: inline-block;
    margin: 0px 0px 0px 0px !important;
    position: relative;
    height: 20px;
    width: 112px;
    margin-top: 35px;
}

.headerSec .headerText .ClubStoreButton button
{
    /* background: linear-gradient(90deg, rgba(28, 78, 255, 0) 0%, rgba(255, 102, 221, 0) 26.38%, #B53598 78.24%); */
    border-radius: 10px;
    padding: 15px;
    position: relative;
    overflow: hidden;
    outline: 1px dashed white;
}
.headerSec .headerText .ClubStoreButton button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(28, 78, 255, 0) 0%, rgba(255, 102, 221, 0) 0%, #B53598 78.24%);
    transition: left 0.25s ease;
    z-index: -1;
  }
  
  .headerSec .headerText .ClubStoreButton button:hover::before {
    left: 0;
  }

.headerSec .headerText .ClubStoreButton button:hover {
  background-position: 0% center;
}
.smallVideo
{
    display: flex;
    align-items: end;
    
}
.smallVideo img
{
    border-radius: 12px;
}
.smallVideo .playBtn
{
    background-color: #545454;
    padding: 14px 15px 8px 15px;
    border-radius: 25px;
    display: inline-block;
    margin-left: 15px;
        display: none!important;
}
.Media_News_Sec
{
    display: flex;
    justify-content: end;
    padding: 1% 5%;
    background: #F1F2F3;
}
.News1,
.News2 a
{
    display: flex;
}
.News2
{
    margin-left: 80px;
}
.videoText
{
    margin: 15px 50px 0px 0px;
}
.videoText p
{
    cursor: pointer !important;
}
.DynasysNetworkSecHead
{
    padding: 0% 5%;
}
.DynasysNetworkImg
{
    position: relative;
}
.dunasysnetworksText2
{
    position: absolute;
    top: 15%;
    left: 4%;
}
.dunasysnetworksText
{
    display: none;
}

.DynasysNetworkBtnSec,.whoWeareBtn,.leaderBtn
{
    display: flex;
    background: black;
    margin-top: -3px;
}
.whoWeareBtn
{
    border-right: 1px solid #FFFFFF;
}

.whoWeareBtn .ClubStoreButton button,
.leaderBtn .ClubStoreButton button {
    transition: transform 0.3s;
  }
.whoWeareBtn .ClubStoreButton button:hover,
.leaderBtn .ClubStoreButton button:hover
{
    transform: scale(1.2);
    font-weight: 600;
}
.whoWeareBtn,.leaderBtn
{
    width: 50%;
    display: flex;
    justify-content: center;
    padding: 35px 0px;
    background: linear-gradient(180deg, rgba(28, 78, 255, 0) 0%, rgba(255, 102, 221, 0) 26.38%, #B53598 150%);

}
.whoWeareBtn:hover,.leaderBtn:hover
{
    background: linear-gradient(180deg, rgba(28, 78, 255, 0) 0%, rgba(255, 102, 221, 0) 26.38%, #B53598 100%);

}

.SolutionSlider
{
    padding: 0% 5% 3% 5%;
    position: relative;
    margin-bottom: 5rem !important;
}
.swiper, swiper-container
{
    position: inherit !important;
}
.swiper-button-next:after, .swiper-button-prev:after
{
    font-size: 24px !important;
    font-weight: 700 !important;
    color: gray;
}
.swiper-button-next,
.swiper-button-prev
{
    padding: 0px 12px !important;
    border-radius: 8px !important;
    border: 1px solid #e8e8e8;
}
.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
    opacity: 0.15 !important;
}
.swiper-container .swiper-button-next {
    right: -25px !important;
  }
  
  .swiper-container .swiper-button-prev {
    left: -25px !important;
  }
.borderSec .border
{
    border:1px solid #A3A3A3;
}
.VerticalsSecHead
{
    padding: 0% 5% 0% 5%;
}
.VerticalsSec
{
    padding: 0% 5% 25px 5%;
    display: flex;
    justify-content: space-between;
}
.verticaleactItem
{
    width: 22%;
}
.verticaleactItem p
{
    font-size: 1.6rem !important;
}
.borderSec
{
    display: flex;
    justify-content: center;
}
.border
{
    width: 92%;
}




video
{
    width: 100%;
    height: auto;
    position: absolute;
    margin: auto;
    width: 100%;
    height: 100%;
    right: -100%;
    bottom: -100%;
    top: -100%;
    left: -100%;
    object-fit: cover;
    z-index: -100;
}


#preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  #preloader-inner {
    text-align: center;
  }
  
  #preloader-inner span {
    font-size: 24px;
  }

  .preloaderbor {
    position: relative;
    width: 160px;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 3px solid #ccc;
    border-top-color: #333;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  .preloadercontent {
    position: relative;
    z-index: 1;
    text-align: center;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }



/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) 
{
    /* .Media_News_Sec 
    {
        display: block;
        padding: 25px 5%;
    } */
    .smallVideo
    {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .smallVideo img
    {
        width: 85% !important;
    }
    .smallVideo .playBtn img
    {
        width: auto !important;
    }
    
    .headerSec {
        padding: 5% 6%;
    }
    .swiper-button-next:after, .swiper-button-prev:after {
        color: black;
    }
    .swiper-button-next, .swiper-button-prev {
        border: 1px solid #e8e8e8;
        background: white;
    }
    .dunasysnetworksText
    {
        display: block;
        background-color: black;
        padding: 6% 5% 1% 5%;
    }

    .dunasysnetworksText2
    {
        display: none;
    }
    .playBtn
    {
      display: none !important;
    }
    .videoText p:nth-child(1)
    {
        font-size: 3rem;
    }
    .videoText p:nth-child(2)
    {
        margin: 10px 0px 20px 0px;
    }
}
