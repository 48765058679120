.PressReleaseBody {
    padding: 0% 5%;
    margin-bottom: 15rem;
}

.PressRelease_Img_Sec {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    margin-bottom: 80px;
}
.PressReleaseBody .PressReleaseBodyTopText p:nth-child(1) {
    width: 60%;
}

.Left_Sec {
    width: 55%;
}

.Right_Sec_Mob {
    display: none;
}

.Right_Sec {
    width: 30%;
}
.Right_Sec img
{
    border-left: 18px solid #F5F5F5;
    border-bottom: 18px solid #F5F5F5F5;
}

.Press_Release_bottom {
    margin-top: 80px;
}

.SiteLinkSec {
    display: flex;
}

.SiteLinkSec a {
    border-bottom: 1px solid black;
}
.Left_Sec p:nth-child(1) span
{
    font-weight: 800;
}
.DynasysPortnet.SiteLinkSec
{
    display: block !important;
}
.DynasysPortnet.SiteLinkSec ul li
{
    font-size: 1.8rem;
    margin-bottom: 1rem;
}
.DynasysPortnet.SiteLinkSec ul li p
{
    margin-right: 1rem;
}
.DynasysPortnet.SiteLinkSec ul li p,
.DynasysPortnet.SiteLinkSec ul li .ClubStoreButton
{
    display: inline-block;
}
.DynasysPortnet.SiteLinkSec ul li .ClubStoreButton a
{
    border-bottom: none !important;
}
@media only screen and (max-width: 600px) {
    .PressReleaseBody .PressReleaseBodyTopText p:nth-child(1) {
        width: 90%;
    }
    .Left_Sec p:nth-child(1)
    {
        display: block !important;
    }
    .PressReleaseBody .PressReleaseBodyTopText p:nth-child(3) {
        padding: 0px 0px 0px 0px !important;
    }

    .PressRelease_Img_Sec {
        display: block !important;
    }

    .Right_Sec_Mob {
        display: block;
        margin-bottom: 80px;
        width: 80%;
    }

    .Right_Sec {
        display: none !important;
    }
    .Left_Sec
    {
        width: 100%;
    }

    .SiteLinkSec p:nth-child(1) {
        display: block;
    }
    .SiteLinkSec
    {
        display: block;
    }
}