.NewOpeningsTopper {
    padding: 0 5%;
    margin: 0 5%;
    border-radius: 12px;
    box-shadow: 0px 0px 3px 0px black;
    margin-bottom: 5rem;
}

.NewOpeningName {
    padding-top: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.NewOpeningButtons {
    display: flex;
    align-items: center;
    width: 50%;
    justify-content: end;
}

.NewOpeningButtons p:nth-child(2) {
    white-space: nowrap;
    margin-left: 2rem;
}

/* .OpeningRequirements {
    padding: 0 5%;
} */
.EmailApply {
    display: flex;
}

.EmailApply button {
    border-bottom: 1px solid black;
}

.Collapsible span {
    font-size: 2rem !important;
    display: block;
    cursor: pointer;
    padding: 2%;
    background-color: #EFEFEF;
}

.Collapsible__trigger.is-closed {
    background-image: url(../../../public/Images/accodianclose.png);
    background-repeat: no-repeat;
    background-position: 96% center;

}

.Collapsible__trigger.is-open {
    background-image: url(../../../public/Images/accodiancopen.png);
    background-repeat: no-repeat;
    background-position: 96% center;
    background-color: #2D2D2D;
    color: white;
}

.Collapsible {
    margin-bottom: 1rem;
}

.Collapsible .JobRequirqment {
    padding: 0% 2% 0% 2%;
    margin-top: 2rem;
    background-image: url(../../../public/Images/careerPageArrow.png);
    background-repeat: no-repeat !important;
    background-position: 8px 4px !important;
    background-size: 0.5% !important;
}

.Collapsible-heading {
    padding: 0% 2% 0% 2%;
    margin-top: 2rem;
    background-image: none;
    background-repeat: no-repeat;
    background-position: 8px 4px;
    background-size: 0.5%;
}

.Collapsible__contentInner {
    margin-bottom: 2rem;
}

.CareerOpenings .ThreeColumnWrapper {
    justify-content: flex-start;
    margin-top: 8rem;
}

.CareerOpenings .ThreeColumnWrapper .col2 {
    margin-left: 4rem;
}

.ApplyFormModal {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #2d2d2deb;
    top: 0;
    left: 0;
    overflow: hidden;
}

.ApplyFormModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    /* Optional: To add a dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Ensure it's on top of other elements */
}

.ApplyForm {
    background: white;
    /* Or any background color you prefer */
    padding: 2rem;
    border-radius: 8px;
}

.ApplyForm form input {
    display: block;
    width: 100%;
    margin-top: 0.5rem;
}

.ApplyForm form label {
    margin-top: 0.5rem;
}

.Mobile {
    display: none;
}

.Desktop.closed button {
    cursor: not-allowed;
    opacity: 0.5;
}

.Desktop button,
.Mobile button {
    color: white;
    background: #212121;
    font-size: 2rem;
    font-weight: 600;
    font-family: Titillium Web, sans-serif;
    border: none;
    padding: 1% 4%;
    margin: 4rem 0rem;
    border-radius: 8px;
    width: auto;
    text-transform: auto;
    white-space: nowrap;
    cursor: pointer;
}

.Desktop button:disabled,
.Mobile button:disabled {
    opacity: 0.2;
    pointer-events: none;
}

@media screen and (max-width: 992px) {

    .NewOpeningName {
        display: block;
    }

    .NewOpeningButtons {
        width: 100%;
        justify-content: flex-start;
        margin-top: 2rem;
    }
}

@media screen and (max-width: 768px) {
    .Desktop {
        display: none;
    }

    .Mobile {
        display: block;
    }

    .NewOpeningButtons p {
        text-align: center;
    }

    .CurrentOpeningsButton .ClubStoreButton button,
    .Mobile button {
        padding: 10px 45px;
    }

    .Collapsible__trigger.is-closed {
        background-size: 15px;
    }

    .CurrentOpeningsButton .ClubStoreButton button {
        margin-left: 0rem !important;
        margin-top: 2rem;
    }

    .Collapsible span {
        padding: 15px;
    }

    .CareerPage .PageDescription .col1 {
        width: 100%;
    }

    .Collapsible p {
        background-size: 1.5%;
        padding: 0 2% 0 6% !important;
        background-position: 8px 2px;
    }

    .NewOpeningButtons p {
        text-align: center;
    }
}

@media screen and (max-width: 576px) {
    .NewOpeningButtons {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: baseline;
    }

    .NewOpeningButtons p {
        display: inline-block;
    }

    .NewOpeningButtons p:nth-child(2) {
        margin-top: 2rem;
        margin-left: 0rem;
    }
}

@media screen and (max-width: 425px) {}