
.leadershipcontainer
{
    height: 80vh;
    display: flex;
    padding: 0% 5%;
    margin-top: 50px;
}

@media only screen and (max-width: 600px) 
{
    .leadershipcontainer
    {
        display: block;
        height: auto;
        margin-bottom: 80px;
    }
}