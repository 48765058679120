.ThreeColumnWrapper {
    padding: 0% 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 6rem;
}

.ThreeColumnWrapper .col1,
.ThreeColumnWrapper .col2,
.ThreeColumnWrapper .col3,
.ThreeColumnWrapper .col4 {
    width: 28%;
}
.ThreeColumnWrapper .col1 p span,
.ThreeColumnWrapper .col2 p span
{
    font-weight: 600;
    font-size: 2.2rem;
}

@media only screen and (max-width: 768px) {
    .ThreeColumnWrapper .col1, .ThreeColumnWrapper .col2, .ThreeColumnWrapper .col3 {
        width: 100%;
    }
    .ThreeColumnWrapper .col1 p:nth-child(1), .ThreeColumnWrapper .col2 p:nth-child(1), .ThreeColumnWrapper .col3 p:nth-child(1) {
        height: auto;
        margin: 0px 0px 25px 0px !important;
    }
    .ThreeColumnWrapper .col4 img
    {
        display: none;
    }
    .CareerOpenings .ThreeColumnWrapper .col2
    {
        margin-left: 0rem !important;
    }
}