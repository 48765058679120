.DistuptPressReleaseBody
{
    margin-bottom: 50px;
    padding: 0 5%;
}
.Disrupt_Pres_image_sec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px 0px 45px;;
    border: 1px solid #dfdfdf;
    border-radius: 19px;
    margin-bottom: 50px;
}
.DisruptTextContent p:nth-child(1) span
{
    font-weight: 800;
}
.Disrupt_Pres_image_sec_Left_Text p:nth-child(2)
{
    border-bottom: 1px solid black;
    display: inline-block;
    padding-bottom: 15px;
    padding-right: 15px;
}
.Disrupt_Pres_image_sec_Left_Text
{
    margin-top: 20px;
}
.Disrupt_Pres_image_sec_Left_Image
{
    margin-top: 60px;
    display: flex;
    margin-bottom: 25px;
}
.Mob_Disrupt_Pres_image_sec_Left_Text,
.MobDisrupt_Pres_image_sec_right,
.MobDisrupt_Pres_image_sec_Left_Image
{
    display: none;
}
.DistuptPressReleaseBody .Top_Text p:nth-child(1)
{
    width: 70%;
}

@media only screen and (max-width: 992px)
{
    .Mob_Disrupt_Pres_image_sec_Left_Text,
    .MobDisrupt_Pres_image_sec_right,
    .MobDisrupt_Pres_image_sec_Left_Image
{
    display: block;
}
.Disrupt_Pres_image_sec 
{
    padding-top: 30px;
    padding-bottom: 30px;
}
.Disrupt_Pres_image_sec_Left_Text,
.Dis.MobDisrupt_Pres_image_sec_Left_Image,
.Disrupt_Pres_image_sec_Left_Image,
.Disrupt_Pres_image_sec_right
{
    display: none;
}
.Mob_Disrupt_Pres_image_sec_Left_Text p:nth-child(2) {
    border-bottom: 1px solid black;
    display: inline-block;
    padding-bottom: 15px;
    padding-right: 15px;
}
.MobDisrupt_Pres_image_sec_right
{
    margin: 25px 0px;
}
/* .MobDisrupt_Pres_image_sec_Left_Image img:nth-child(2)
{
    border-left: 1px solid black !important;
    padding-left: 25px;
    margin-left: 25px;
} */
    .Disrupt_Pres_image_sec
    {
        flex-direction: column-reverse;
        padding-left: 0px;
    }
    .Disrupt_Pres_image_sec_Left
    {
        text-align: center;
    }
    .DisruptXPressRelease .DistuptPressReleaseBody p:nth-child(3)
    {
        padding-right: 0px !important;
    }
    .Disrupt_Pres_image_sec_Left_Text p:nth-child(2) {
        padding-right: 0px;
    }
}

@media only screen and (max-width: 576px)
{
    .Disrupt_Pres_image_sec_Left_Image
    {
        justify-content: space-around;
    }
    .Disrupt_Pres_image_sec_Left_Image img
    {
        width: 45% !important;
    }
    .Disrupt_Pres_image_sec_Left_Image img:nth-child(2)
    {
        margin-left: 0px;
        margin-top: 25px;
    }
    .DistuptPressReleaseBody .Top_Text p:nth-child(1) {
        width: 100%;
    }
}