.LeaderShipLink a {
    text-align: center;
    margin-right: 120px;
    display: block;
}
.leaderImg img
{
    image-rendering: pixelated;
}


.LeaderShipLink a p
{
    cursor: pointer;
}
.leaderDetails
{
    margin-top: 15px;
}

@media only screen and (max-width: 600px) 
{
    .LeaderShipLink
    {
        display: block;
        height: auto;
        margin-bottom: 80px;
    }
    .leadership
    {
        margin-left: 0px;
        margin-bottom: 50px;
    }
    .LeaderShipLink a {
        margin-right: 0px;
    }
}
